var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { flat: "" } },
    [
      _c(
        "v-subheader",
        { staticClass: "text-uppercase font-weight-bold text-primary" },
        [_vm._v(_vm._s(_vm.$t("VehiclesFound")))]
      ),
      _c(
        "v-list-item-group",
        {
          attrs: { color: "primary" },
          model: {
            value: _vm.selectedItem,
            callback: function ($$v) {
              _vm.selectedItem = $$v
            },
            expression: "selectedItem",
          },
        },
        _vm._l(_vm.vehicles, function (item, i) {
          return _c(
            "v-list-item",
            { key: i },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v(" mdi-car")])], 1),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      _vm._s(item.year) +
                        " - " +
                        _vm._s(item.make) +
                        " - " +
                        _vm._s(item.model)
                    ),
                  ]),
                  _c("v-list-item-subtitle", [_vm._v(_vm._s(item.trim))]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }