var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "hide-overlay": _vm.hiddenOverlay,
            persistent: "",
            width: "300",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "green darken-3", dark: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-2" },
                [
                  _vm._v(" " + _vm._s(_vm.loadingMessage) + " "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0 ",
                    attrs: { indeterminate: "", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }